import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { filterNilValue } from '@datorama/akita';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { distinctUntilChanged, map, switchMap } from 'rxjs';

import { DashboardConfig, Search } from 'models';
import { AppConfigQuery } from 'src/app/modules/app-config';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SearchUIService } from 'src/app/services/search-ui.service';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

/**
 * Search dashboard.
 *
 * @todo The leftsidebar should be hidden if mode is guest.
 * @todo Get dashboard searches from state Query.
 */
@UntilDestroy()
@Component({
  selector: 'app-search-dashboard',
  templateUrl: './search-dashboard.component.html',
  styleUrls: ['./search-dashboard.component.scss'],
})
export class SearchDashboardComponent implements OnInit {
  /**
   * Observable configuration for the dashboard header.
   *
   * This provides the title, message, and image to be shown in a given context
   * based on the user and database.
   *
   * Uses the active database to start the pipeline so we update the filter and
   * sort rules for selecting the matching dashboard on any change of active
   * database.
   */
  dashboardConfig$ = this.databasesQuery.selectActive().pipe(
    distinctUntilChanged(),
    filterNilValue(),
    switchMap(() => {
      return this.appConfig.dashboards$.pipe(
        map((dashboards) => {
          // Filter the list to only dashboards applicable to current state.
          dashboards = this.filterDashboards(dashboards);
          // Sort dashboards by priorty.
          dashboards = this.sortDashboards(dashboards);
          // Return the first match or a default.
          return dashboards.length > 0
            ? dashboards[0]
            : ({
                message: this.translate.translate('DASHBOARD_MESSAGE_DEFAULT'),
                title: this.translate.translate('DASHBOARD_TITLE_DEFAULT'),
                scope: 'everyone',
              } as DashboardConfig);
        })
      );
    })
  );
  /** Number of grid columsn to display. */
  gridCols: number;
  /** Search Filter. */
  searchFilter = '';
  /** Observable of dashboard configured searches. */
  searches$ = this.searchesQuery.dashboardSearches$;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private searchesQuery: SearchesQuery,
    private searchUIService: SearchUIService,
    private databasesQuery: DatabasesQuery,
    private appConfig: AppConfigQuery,
    private logger: NGXLogger,
    private auth: AuthenticationService,
    private translate: TranslocoService
  ) {}

  /**
   * Is the user a guest?
   *
   * @returns True if the user is a guest.
   * */
  get isGuest() {
    return this.auth.isGuest;
  }

  ngOnInit(): void {
    this.subscribeToBreakpointChanges();

    // Fill searchFilter from query string parameter.
    this.searchFilter = this.searchesQuery.filter;
  }

  /**
   * The click event for selecting a search without prompt.
   *
   * @param event Event.
   * @param search Clicked search.
   * @param advanced Advanced search mode.
   */
  onSearchClick(event: Event, search: Search, advanced: boolean = false): void {
    event.stopPropagation();
    this.logger.debug('Search clicked.', search);

    if (advanced) {
      this.searchUIService.runWithPrompt(
        search,
        this.databasesQuery.activeId,
        search.archives[0]
      );
      return;
    }

    this.searchUIService.redirectToSearch(
      this.databasesQuery.activeId,
      search.archives[0],
      search.id
    );
  }

  /**
   * The click event for selecting a search with UI prompt.
   *
   * @param search Clicked search.
   */
  onSearchWithPromptClick(search: Search) {
    this.logger.debug('Search clicked.', search);
    this.searchUIService.runWithPrompt(
      search,
      this.databasesQuery.activeId,
      search.archives[0]
    );
  }

  /**
   * Determine priority value of a dashboard.
   *
   * @param dashboard Dashboard.
   * @returns A number based on the database and scope properties of a dashboard.
   */
  private dashboardPriority(dashboard: DashboardConfig) {
    // If it has a database, assign a high value.
    let priority = dashboard.database ? 100 : 0;
    // If it has a scope of 'guest' or 'users', assign a medium value.
    if (dashboard.scope === 'guest' || dashboard.scope === 'users') {
      priority += 10;
    }
    // If it has a scope of 'everyone', assign a low value.
    if (dashboard.scope === 'everyone') {
      priority += 1;
    }
    return priority;
  }

  /**
   * Filter dashboards based on context.
   *
   * @param dashboards Dashboards.
   * @returns Filtered dashboards.
   */
  private filterDashboards(dashboards: DashboardConfig[]): DashboardConfig[] {
    return dashboards.filter((dashboard) => {
      // Ignore dashboards that specify another database.
      if (
        dashboard.database &&
        dashboard.database !== this.databasesQuery.activeId
      )
        return false;
      // Ignore dashboards for guest only, if the user is not a guest.
      if (dashboard.scope === 'guest' && !this.auth.isGuest) return false;
      // Ingore dashboards for authenticated users, if the user is a guest.
      if (dashboard.scope === 'users' && this.auth.isGuest) return false;

      // Otherwise, it is applicable.
      return true;
    });
  }

  /**
   * Sort DashboardConfig occording to priority.
   *
   * @param dashboards Dashboards to sort.
   * @returns Sorted dashboards.
   */
  private sortDashboards(dashboards: DashboardConfig[]): DashboardConfig[] {
    return dashboards.sort((a: DashboardConfig, b: DashboardConfig) => {
      return this.dashboardPriority(b) - this.dashboardPriority(a);
    });
  }

  /**
   * Watch for and react to changes in breakpoints.
   */
  private subscribeToBreakpointChanges() {
    this.breakpointObserver
      .observe([
        Breakpoints.XLarge,
        Breakpoints.Large,
        Breakpoints.Medium,
        Breakpoints.Small,
        Breakpoints.XSmall,
      ])
      .pipe(untilDestroyed(this))
      .subscribe((media) => {
        this.logger.debug('Setting grid to match media:', media);
        if (media.breakpoints[Breakpoints.XLarge]) this.gridCols = 5;
        else if (media.breakpoints[Breakpoints.Large]) this.gridCols = 4;
        else if (media.breakpoints[Breakpoints.Medium]) this.gridCols = 3;
        else if (media.breakpoints[Breakpoints.Small]) this.gridCols = 2;
        else if (media.breakpoints[Breakpoints.XSmall]) this.gridCols = 1;
      });
  }
}
