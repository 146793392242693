import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { Observable, combineLatest, map, merge, of, startWith } from 'rxjs';

import { Field, List, ListType } from 'models';
import { valueIsInList } from 'src/app/common/form-validators';
import { ListsService } from 'src/app/state/lists/lists.service';

import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { filterList } from 'src/app/common/utility';
import { FieldBaseComponent } from '../field-component.base.component';
import { ListFieldBaseComponent } from '../list-field-base.component';
import { MultiValueFieldMenuComponent } from '../multi-value-field-menu/multi-value-field-menu.component';

/** Dropdown Field Component. */
@UntilDestroy()
@Component({
  selector: 'app-dropdown-field',
  templateUrl: './dropdown-field.component.html',
  styleUrls: ['./dropdown-field.component.scss'],
})
export class DropdownFieldComponent
  extends FieldBaseComponent
  implements OnInit, AfterViewInit, ListFieldBaseComponent
{
  /**
   * Alternate label for the mat-form-field.
   *
   * The field.name will be used if this is not provided.
   */
  @Input()
  alternateFormLabel: string;
  /** Form control for the field. */
  @Input('form-control')
  control: UntypedFormControl;
  /** Field. */
  @Input()
  field: Field;
  /** MV Field Menu Component. */
  @Input('mv-field-menu')
  mvFieldMenu: MultiValueFieldMenuComponent;
  /**
   * Primary list form control.
   *
   * This only applies to dynamic list fields.
   *
   * If not set we attempt to get it from the parent form group.
   */
  @Input('list-primary-filter-control')
  primaryListFilterControl?: UntypedFormControl;
  /**
   * Secondary list form control.
   *
   * This only applies to dynamic list fields.
   *
   * If not set we attempt to get it from the parent form group.
   */
  @Input('list-secondary-filter-control')
  secondaryFilterControl?: UntypedFormControl;
  /** Emits when this field is blurred. The event contains the field that was blurred. */
  @Output()
  fieldBlurred = new EventEmitter<Field>();
  /**
   * Emits when this field is focused. The event contains the field that was focused.
   */
  @Output()
  fieldFocused = new EventEmitter<Field>();
  /** Autocomplete trigger for large lists. */
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;
  /** Input element reference. */
  @ViewChild('input')
  inputElement: ElementRef;
  /** Select element reference. */
  @ViewChild('select')
  selectElement: MatSelect;

  /** Observable list of filtered values. */
  filteredValues$: Observable<string[]>;
  /** Field list or undefined if the list has not yet loaded. */
  list: List | undefined;
  /** List types. */
  listType: ListType;

  /** Indicates whether this field should immediately focus after a list is loaded. */
  private focusOnLoad = false;

  constructor(
    translate: TranslocoService,
    private lists: ListsService,
    private logger: NGXLogger
  ) {
    super(translate);
  }

  /**
   * Get the label for the mat-form-field.
   *
   * @returns The label string for the mat-form-field
   */
  get fieldLabel(): string {
    return this.alternateFormLabel || this.field.name;
  }

  /**
   * Determines if the list is large.
   *
   * @returns True if the list exceeds 1000 entries.
   */
  get isLargelist(): boolean {
    return (this.list?.values.length ?? 0) > 1000;
  }

  /** @inheritdoc */
  closeListPanel(): void {
    if (this.isLargelist) {
      this.autocompleteTrigger.closePanel();
    } else {
      this.selectElement.close();
    }
  }

  /** @inheritdoc */
  focus(): void {
    if (!this.list) {
      this.focusOnLoad = true;
      return;
    }
    if (this.isLargelist) {
      this.inputElement.nativeElement.focus();
      this.inputElement.nativeElement.select();
    } else {
      this.selectElement.focus();
    }
  }

  /**
   * Load the dynamic list using primary and secondary filters.
   *
   * @param primaryFilter Primary filter.
   * @param secondaryFilter Secondary filter.
   */
  loadDynamicList(primaryFilter: string, secondaryFilter: string): void {
    this.list = undefined;
    this.lists
      .getDynamicList(this.field.id, primaryFilter, secondaryFilter)
      .subscribe((list) => {
        this.list = list;
        if (!this.list.values.includes(this.control.value)) {
          // Force the value back to empty if it is not longer in the list.
          this.control.setValue('');
        }
        this.control.updateValueAndValidity();
        if (this.focusOnLoad) {
          this.focusOnLoad = false;
          // Force the focus to the control until the next render cycle.
          setTimeout(() => this.focus());
        }
      });
  }

  ngAfterViewInit(): void {
    this.listenForList();
    this.filteredValues$ = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => filterList(value, this.list?.values ?? [], 100))
    );
  }

  ngOnInit(): void {
    this.addValidators();
    if (
      this.field.list.listId === 0 &&
      this.field.list.primary === 0 &&
      this.field.list.secondary === 0
    ) {
      throw new Error('Field does not contain a list.');
    }

    // Add is in list validator
    this.control.addValidators(valueIsInList(() => this.list?.values));
  }

  /** @inheritdoc */
  onBlur(): void {
    this.fieldBlurred.emit(this.field);
  }

  /** @inheritdoc */
  onFocus(): void {
    this.fieldFocused.emit(this.field);
  }

  /**
   * Handler for the select change event.
   */
  onSelectionChange(): void {
    if (this.field.multiValue) {
      this.mvFieldMenu.addMultiValue.emit({ append: true });
    }
  }

  private listenForControlChanges(): void {
    // Listen to changes to the control so we can be sure the select always closes when value changes.
    this.control.valueChanges.subscribe(() => {
      // There is a small chance the select will not exist depending on if something tries to change the control before this control inits.
      this.selectElement?.close();
    });
  }

  private listenForDynamicList(): void {
    // If there is no parent we can't do anything here.
    if (!this.control.parent) {
      return;
    }

    // Gets the primary list field control
    const primaryFilterControl = this.primaryListFilterControl
      ? this.primaryListFilterControl
      : this.control.parent.get(this.field.list.primary.toString());
    if (!primaryFilterControl) {
      this.logger.error(
        'Unable to retrieve primary filter control for dynamic list.'
      );
      return;
    }

    // Store the initial primary value so we can load the initial list
    const initialPrimaryValue = primaryFilterControl.value;

    if (!initialPrimaryValue) {
      this.control.disable();
    }
    let secondaryValue$: Observable<any>;
    let initialSecondaryValue = '';

    // Merge the intial value and any changes to the value into a single observable value
    const primaryValue$ = merge(
      of(initialPrimaryValue),
      primaryFilterControl.valueChanges.pipe(untilDestroyed(this))
    );

    let secondaryFilterControl: AbstractControl<any, any> | null = null;

    if (this.field.list.secondary > 0) {
      secondaryFilterControl = this.secondaryFilterControl
        ? this.secondaryFilterControl
        : this.control.parent.get(this.field.list.secondary.toString());
      if (!secondaryFilterControl) {
        this.logger.error(
          'Unable to retrieve primary filter control for dynamic list.'
        );
        return;
      }

      // Store the initial secondary value if needed.
      initialSecondaryValue = secondaryFilterControl.value;

      if (!initialSecondaryValue) {
        this.control.disable();
      }

      // Merge the intial value and any changes to the value into a single observable value
      secondaryValue$ = merge(
        of(initialSecondaryValue),
        secondaryFilterControl.valueChanges.pipe(untilDestroyed(this))
      );
    } else {
      // If there is no secondary filter, just return an empty observable.
      secondaryValue$ = of('');
    }

    // Listen for changes to primary and secondary filter controls.
    combineLatest([primaryValue$, secondaryValue$]).subscribe(
      ([primaryValue, secondaryValue]) => {
        const primaryOrSecondaryInvalid =
          primaryFilterControl.invalid || secondaryFilterControl?.invalid;
        if (
          !primaryValue ||
          (this.field.list.secondary && !secondaryValue) ||
          primaryOrSecondaryInvalid
        ) {
          // Disable the control if there should be a value in a primary or secondary control but there isn't.
          this.control.disable();
        } else {
          this.control.enable();
        }
        this.loadDynamicList(primaryValue, secondaryValue);
      }
    );
  }

  private listenForList(): void {
    if (this.field.list.primary > 0 || this.field.list.secondary > 0) {
      this.listenForDynamicList();
    } else {
      this.list = undefined;
      this.lists
        .get(this.field.list.listId)
        .pipe(untilDestroyed(this))
        .subscribe((list) => {
          this.list = list;
          // Force validation to ensure validator applies
          this.control.updateValueAndValidity();
          this.listenForControlChanges();
          if (this.focusOnLoad) {
            this.focusOnLoad = false;
            // Force the focus to the control until the next render cycle.
            setTimeout(() => this.focus());
          }
        });
    }
  }
}
